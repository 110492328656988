<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-center" v-if="!interview">
      <v-col>
        <div class="mt-5 text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p class="body font-weight-thin">Chargement...</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col class="fill-height">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';

export default {
  name: 'InterviewToolSemantic',
  mixins: [MixinPatients, MixinInterviews],
  props: {
    patientId: String,
    interviewId: String,
  },
  methods: {
    url(path) {
      return `/patient/${this.patientId}/interview/${this.interviewId}/${path}`;
    },
  },
  async mounted() {
    this.result = await this.getAnalysisResult(this.patientId, this.interviewId);
    this.interview = await this.getInterviewInfo(this.patientId, this.interviewId);
  },
  data: () => ({
    result: null,
    interview: null,
  }),
};
</script>
